@import '~material-design-icons/iconfont/material-icons.css';
@import '~typeface-roboto/index.css';

/* You can add global styles to this file, and also import other style files */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

*{
	font-family: "Open Sans", Calibri, Arial;
}
html, body { height: 100%; }
body { margin: 0; font-family: "Open Sans", Calibri, Arial; }

/* Custom */
.container{
    height: 100%;
}


h2{
    font-size: 28px;
}
h3{
    font-size: 24px;
}

.mat-progress-spinner circle, .mat-spinner circle {
    stroke: #1F69B2;
}

.mat-step-header .mat-step-icon-selected {
    background-color: #1F69B2;
 }